/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';
import {createPortal} from "react-dom";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 200ms linear;

  &.is-open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
`;

const ModalInner = styled.div<StyledModalInnerProps>`
  background-color: ${colors.white};
  &.no-padding {
    padding: 0 !important;
  }
  &.max-width-90vw {
    max-width: 90vw;

    ${respondFrom(
      breakpoints.xxl,
      css`
        max-width: 1280px;
      `
    )}
  }

  ${props =>
    props.showMobileBackdrop
      ? css`
          position: relative;
          width: 90%;
          height: auto;
          max-height: calc(100vh - 40px);
          max-width: 750px;
          padding: 35px 15px;
          overflow: auto;

          ${respondFrom(
            breakpoints.md,
            css`
              padding: 65px 50px 35px;
            `
          )}
        `
      : css`
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: auto;
          padding: 80px 30px 35px;
          overflow: auto;

          ${respondFrom(
            breakpoints.md,
            css`
              position: relative;
              width: 90%;
              height: auto;
              max-height: calc(100vh - 40px);
              max-width: 750px;
              padding: 65px 50px 35px;
            `
          )}
        `}

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 100%;
      max-height: calc(100vh - 80px);
    `
  )}
`;

const ModalClose = styled.button<StyledModalCloseProps>`
  position: absolute;
  top: 45px;
  right: 30px;
  background: transparent;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;

  ${respondFrom(
    breakpoints.md,
    css`
      top: 30px;
    `
  )}

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 3px;
    height: 30px;
    background-color: ${colors.green.dark};
    transform: rotate(45deg);
    ${({ closeColor }) =>
      closeColor === 'black' &&
      css`
        background-color: ${colors.black};
      `};
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

interface ModalPorps extends StyledModalInnerProps, StyledModalCloseProps {
  content?: any;
  isOpened: boolean;
  hideButtonClose?: boolean;
  disableClickOutside?: boolean;
  onCloseModalPanel?: Function;
  innerClassName?: string;
}

interface StyledModalInnerProps {
  showMobileBackdrop?: boolean;
}

interface StyledModalCloseProps {
  closeColor?: 'black';
}

class Modal extends Component<ModalPorps> {
  state = {
    modalOpened: false,
  };

  componentDidMount() {
    this.setState({ modalOpened: this.props.isOpened });

    document.addEventListener('keyup', this.onKeypressEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeypressEscape);
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.isOpened !== this.props.isOpened) {
      this.setState({ modalOpened: this.props.isOpened });
    }
    return null;
  }

  onCloseModal = () => {
    this.setState({ modalOpened: false }, () => {
      if (this.props.onCloseModalPanel) {
        this.props.onCloseModalPanel();
      }
    });
  };

  onClickOutsideModal = e => {
    if (
      !this.props.disableClickOutside &&
      [...e.target.classList].find(className => className === 'modal-wrapper')
    ) {
      this.onCloseModal();
    }
  };

  onKeypressEscape = e => {
    if (e.key === 'Escape') {
      this.onCloseModal();
    }
  };

  renderContent = (content: Pick<ModalPorps, 'content'>['content']) =>
    (typeof content === 'string') ? utils.SafeHtml(content) : content;

  render() {
    if (typeof window === 'undefined') {
      return null;
    }

    return (
      <>
        {createPortal(
          <ModalWrapper
            className={this.state.modalOpened ? 'modal-wrapper is-open' : 'modal-wrapper'}
            onClick={e => this.onClickOutsideModal(e)}
          >
            <ModalInner
              className={this.props.innerClassName || ''}
              showMobileBackdrop={this.props.showMobileBackdrop}
            >
              {!this.props.hideButtonClose && (
                <ModalClose closeColor={this.props.closeColor} onClick={this.onCloseModal} />
              )}
              <div>{this.props.content && this.renderContent(this.props.content)}</div>
            </ModalInner>
          </ModalWrapper>,
          document.body
        )}
      </>
    );
  }
}

export default Modal;
