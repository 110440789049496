/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash.get';
import {
  Node__DevicesConnection,
  Node__DevicesEdge,
  Node__Devices,
  Node__Cases,
  Node__AccessoriesConnection,
  Node__AccessoriesEdge,
  Node__Accessories,
  File__File,
  Node__TariffsConnection,
  Node__Tariffs,
  Node__TariffsEdge,
  Paragraph__Profits,
  Taxonomy_Term__Pricelist_GroupConnection,
  Taxonomy_Term__Pricelist_Group,
  Taxonomy_Term__Pricelist_GroupEdge,
  Node__Price_ListConnection,
  Node__Price_ListEdge,
  Node__Price_List,
} from '../../graphql/types';
import { FileImageSharp } from '../types/api';

import {Product, Accessory, Tariff, Fiscalization, ProductCategories} from '@store/types';
import { Funding } from '@content/types/funding';
import * as utils from '@utils';
import languages from '@content/json/languages.json';
import { Agreement } from '@content/types/agreement';
import { ProductPrice } from '@content/types/product';

/* helpers */

const findPriceListGroup = (price, priceListGroup) => {
  return (
    get(priceListGroup, 'langcode') === get(price, 'langcode') &&
    get(priceListGroup, 'field_name_in_api') === get(price, 'field_pl_group_name_in_api')
  );
};

/* transformers */

const compare = (a, b) => {
  let compare;

  if (a.field_weight > b.field_weight) {
    compare = 1;
  } else if (a.field_weight < b.field_weight) {
    compare = -1;
  }
  return compare;
};

export const transformProducts = (
  devices?: Node__DevicesConnection | null,
  priceList?: Node__Price_ListConnection,
  priceListGroup?: Taxonomy_Term__Pricelist_GroupConnection | null
): Product[] => {
  if (!devices) {
    return [];
  }

  const dataDevices = get(devices, 'edges', []).map(
    (edge: Node__DevicesEdge) => edge.node
  ) as Node__Devices[];

  const dataPriceList = get(priceList, 'edges', []).map(
    (edge: Node__Price_ListEdge) => edge.node
  ) as Node__Price_List[];

  const dataPriceListGroup = get(priceListGroup, 'edges', []).map(
    (edge: Taxonomy_Term__Pricelist_GroupEdge) => edge.node
  ) as Taxonomy_Term__Pricelist_Group[];

  const allDevices = dataDevices.sort(compare).map(device => {
    const fileImage = get(device.relationships, 'field_pl_image') as FileImageSharp;
    const fileImageForAccessories = get(
      device.relationships,
      'field_pl_image_for_accessories'
    ) as FileImageSharp;

    const devicePriceListName = get(device, 'field_pl_price_list_name', null);
    const deviceAdditionalPriceListNames = get(device, 'field_additional_price_lists', null);
    const deviceLangcode = get(device, 'langcode', null);

    const prices = dataPriceList
      .filter(
        price =>
          devicePriceListName !== null &&
          deviceLangcode !== null &&
          get(price, 'field_pl_group_name_in_api', null) !== null &&
          devicePriceListName === get(price, 'field_pl_name', null) &&
          deviceLangcode === get(price, 'langcode', null)
      )
      .map(price => {
        const activationPrice = get(price, 'field_pl_activation_price', null);
        const contractDuration = get(price, 'field_pl_contract_duration', null);
        const fixedPrice = get(price, 'fixedprice', null);
        const iposFee = get(price, 'iposfee', null);
        const reducedFee = get(price, 'field_pl_reduced_fee', null);
        const reducedFeeDuration = get(price, 'field_pl_reduced_fee_duration', null);
        const priceGroup = dataPriceListGroup.find(priceListGroup =>
          findPriceListGroup(price, priceListGroup)
        );
        return {
          id: get(price, 'drupal_id', ''),
          name: get(price, 'field_pl_name', ''),
          leaseType: get(price, 'field_pl_lease_name', ''),
          paymentType: get(price, 'field_pl_payment_type', ''),
          lease: get(price, 'field_pl_lease', false),
          activationPrice: activationPrice ? parseFloat(activationPrice) : null,
          contractDuration: contractDuration ? parseInt(contractDuration) : null,
          priceListGroup: get(price, 'field_pl_group_name_in_api', null),
          iposFee:
            fixedPrice != null && iposFee !== null
              ? parseFloat(fixedPrice) + parseFloat(iposFee)
              : null,
          reducedFee: reducedFee ? parseFloat(reducedFee) : null,
          reducedFeeDuration: reducedFeeDuration ? parseInt(reducedFeeDuration) : null,
          isDefault: !!get(priceGroup, 'field_is_default', false),
        };
      });

    const additionalPrices = deviceAdditionalPriceListNames.reduce(
      (accumulator: { [key: string]: ProductPrice[] }, name) => {
        accumulator[name] = dataPriceList.reduce((prices: ProductPrice[], price) => {
          if (deviceLangcode !== null) {
            const priceFieldName = get(price, 'field_pl_name', null);
            const priceLangcode = get(price, 'langcode', null);
            const priceGroupName = get(price, 'field_pl_group_name_in_api', null);

            const deviceAdditionalPriceListNamesMatches = name === priceFieldName;

            if (
              deviceAdditionalPriceListNamesMatches &&
              deviceLangcode === priceLangcode &&
              priceGroupName !== null
            ) {
              const activationPrice = get(price, 'field_pl_activation_price', null);
              const contractDuration = get(price, 'field_pl_contract_duration', null);
              const fixedPrice = get(price, 'fixedprice', null);
              const iposFee = get(price, 'iposfee', null);
              const reducedFee = get(price, 'field_pl_reduced_fee', null);
              const reducedFeeDuration = get(price, 'field_pl_reduced_fee_duration', null);
              const priceGroup = dataPriceListGroup.find(priceListGroup =>
                findPriceListGroup(price, priceListGroup)
              );
              prices.push({
                id: get(price, 'drupal_id', ''),
                name: get(price, 'field_pl_name', ''),
                lease: get(price, 'field_pl_lease', false),
                leaseType: get(price, 'field_pl_lease_name', ''),
                paymentType: get(price, 'field_pl_payment_type', ''),
                activationPrice: activationPrice ? parseFloat(activationPrice) : null,
                contractDuration: contractDuration ? parseInt(contractDuration) : null,
                priceListGroup: get(price, 'field_pl_group_name_in_api', null),
                iposFee: fixedPrice !=null && iposFee !==null ? parseFloat(fixedPrice) + parseFloat(iposFee) : null,
                reducedFee: reducedFee ? parseFloat(reducedFee) : null,
                reducedFeeDuration: reducedFeeDuration ? parseInt(reducedFeeDuration) : null,
                isDefault: !!get(priceGroup, 'field_is_default', false),
              });
            }
          }
          return prices;
        }, []);

        return accumulator;
      },
      {}
    );

    return {
      langcode: get(device, 'langcode', ''),
      id: get(device, 'drupal_id', ''),
      name: get(device, 'title', ''),
      subtitle: get(device, 'field_subtitle', ''),
      image: fileImage ? fileImage.localFile : null,
      imageForAccessories: fileImageForAccessories ? fileImageForAccessories.localFile : null,
      hasOnline: get(device, 'field_pl_is_online', false),
      weight: get(device, 'field_weight', 0),
      description: get(device, 'field_pl_description_in_cms.value', '') as string,
      regularPrice: parseFloat(get(device, 'field_pl_strikethrough_price', '')) || 0,
      regularPriceLease: parseFloat(get(device, 'field_lease_strike_through_price', '')) || 0,
      availableColors: (get(device, 'relationships.field_pl_cases', []) as Node__Cases[]).map(
        thecase => {
          const fileCaseImage = get(thecase, 'relationships.field_pl_image') as FileImageSharp;

          return {
            id: get(thecase, 'drupal_id', ''),
            name: get(thecase, 'title', ''),
            image: fileCaseImage ? fileCaseImage.localFile : null,
            hex: `#${thecase.field_pl_color}` || '',
            price: parseFloat(get(thecase, 'relationships.field_date_from_api.fixedprice', 0)),
            recommended: get(thecase, 'relationships.field_recommended_for_branches', []).map(
              recommended => utils.escapeDiacritics(recommended.title).toLowerCase()
            ),
          };
        }
      ),
      availableAccessories: (get(
        device,
        'relationships.field_accessories',
        []
      ) as Node__Accessories[]).map(accessory => {
        return {
          id: get(accessory, 'drupal_id', ''),
        };
      }),
      recommended: get(device, 'relationships.field_recommended_for_branches', []).map(
        recommended => utils.escapeDiacritics(recommended.title).toLowerCase()
      ),
      tooltipTextForBranches: get(device, 'field_tooltip_text_for_branches', ''),
      isVirtual: get(device, 'field_pl_is_virtual', false),
      isAvailable: get(device, 'field_is_available', false),
      leaseAllowed: get(device, 'field_lease_allowed', false),
      paymentTypes: get(device, 'relationships.field_payment_methods', []).map(paymentMethod =>
        get(paymentMethod, 'machine_api_name')
      ),
      productCategoryId: get(device, 'relationships.field_category.drupal_internal__tid', null),
      prices,
      additionalPrices,
      deviceAdditionalPriceListNames,
      popup: {
        body: get(device, 'field_popup_body.value') || '',
        buttonBack: get(device, 'field_popup_button_back') || '',
        buttonOk: get(device, 'field_popup_button_ok') || '',
        enabled: get(device, 'field_popup_enabled') || false,
        title: get(device, 'field_popup_title') || '',
      },
      textWhenUnavailable: get(device, 'field_text_when_unavailable', null),
      buttonTextWhenUnavailable: get(device, 'field_button_when_unavailable', null),
      priceWhenUnavailable: get(device, 'field_price_when_unavailable', null),
    };
  });
  const allDevicesLanguages = {};

  languages.codes.map(lang => {
    allDevicesLanguages[lang] = allDevices.filter(device => device.langcode === lang);
  });

  return allDevicesLanguages;
};

export const transformProductCategories = (
  productCategories?: Taxonomy_Term__Pricelist_GroupConnection
): ProductCategories[] => {
  if (!productCategories) {
    return [];
  }

  const dataProductCategories = get(productCategories, 'edges', []).map(
    edge => edge.node
  );

  const allProductCategories = dataProductCategories.map(category => {
    return {
      tid: get(category, 'drupal_internal__tid', null),
      langcode: get(category, 'langcode', ''),
      name: get(category, 'name', ''),
      tooltip: get(category, 'field_tooltip', ''),
      weight: get(category, 'weight', 0),
      expand_button_text: get(category, 'field_expand_button_text', ''),
      hide_button_text: get(category, 'field_hide_button_text', ''),
    };
  }).sort((a, b) => a.weight - b.weight);

  const allProductCategoriesLanguages = {};

  languages.codes.map(lang => {
    allProductCategoriesLanguages[lang] = allProductCategories.filter(productCategory => productCategory.langcode === lang);
  });

  return allProductCategoriesLanguages;
}

export const transformAccessories = (
  accessories?: Node__AccessoriesConnection | null
): Accessory[] => {
  if (!accessories) {
    return [];
  }

  const data = get(accessories, 'edges', []).map(
    (edge: Node__AccessoriesEdge) => edge.node
  ) as Node__Accessories[];

  const allAccessories = data.sort(compare).map(accessory => {
    const fileImage = get(accessory.relationships, 'field_pl_image') as FileImageSharp;

    const fileImageTable = get(
      accessory.relationships,
      'field_pl_image_accessories_table'
    ) as FileImageSharp;

    const fileImageOutline = get(
      accessory.relationships,
      'field_pl_image_accessories_outl'
    ) as File__File;

    return {
      langcode: get(accessory, 'langcode', ''),
      id: get(accessory, 'drupal_id', ''),
      name: get(accessory, 'title', ''),
      price: parseFloat(get(accessory, 'relationships.field_date_from_api.fixedprice', 0)),
      type: get(accessory, 'relationships.field_pl_accessory_type.name', ''),
      image: fileImage ? fileImage.localFile : null,
      imageTable: fileImageTable ? fileImageTable.localFile : null,
      imageOutline: fileImageOutline
        ? { publicURL: get(fileImageOutline.localFile, 'publicURL', '') }
        : null,
      groupId: accessory.field_pl_groupid || null, // TODO
      recommended: get(accessory, 'relationships.field_recommended_for_branches', []).map(
        recommended => utils.escapeDiacritics(recommended.title).toLowerCase()
      ),
    };
  });

  const allAccessoriesLanguages = {};

  languages.codes.map(lang => {
    allAccessoriesLanguages[lang] = allAccessories.filter(
      accessorie => accessorie.langcode === lang
    );
  });

  return allAccessoriesLanguages;
};

export const transformTariffs = (
  tariffs?: Node__TariffsConnection | null,
  profitReference?,
  priceList?: Node__Price_ListConnection,
  priceListGroup?: Taxonomy_Term__Pricelist_GroupConnection | null
): Tariff[] => {
  if (!tariffs) {
    return [];
  }

  const data = get(tariffs, 'edges', []).map(
    (edge: Node__TariffsEdge) => edge.node
  ) as Node__Tariffs[];

  const dataPriceList = get(priceList, 'edges', []).map(
    (edge: Node__Price_ListEdge) => edge.node
  ) as Node__Price_List[];

  const dataPriceListGroup = get(priceListGroup, 'edges', []).map(
    (edge: Taxonomy_Term__Pricelist_GroupEdge) => edge.node
  ) as Taxonomy_Term__Pricelist_Group[];

  const profRef = get(profitReference, 'edges', []).map(edge => edge.node);

  const allTariffs = data.sort(compare).map(tariff => {
    const tariffPriceListName = 'WEB';
    const tariffPriceListOptionNamePrefix = get(tariff, 'price_list_type', null);
    const tariffLangcode = get(tariff, 'langcode');

    const prices = dataPriceList
      .filter(
        price =>
          tariffLangcode &&
          tariffPriceListOptionNamePrefix &&
          get(price, 'field_pl_group_name_in_api', null) !== null &&
          new RegExp(tariffPriceListOptionNamePrefix).test(get(price, 'field_pl_optionname')) &&
          tariffPriceListName === get(price, 'field_pl_name') &&
          tariffLangcode === get(price, 'langcode')
      )
      .map(price => {
        const priceGroup = dataPriceListGroup.find(priceListGroup =>
          findPriceListGroup(price, priceListGroup)
        );
        return {
          id: get(price, 'drupal_id', ''),
          priceListGroup: get(price, 'field_pl_group_name_in_api', ''),
          iposFee: parseFloat(get(price, 'iposfee', 0)),
          locationFee: parseFloat(get(price, 'field_pl_locationfee', 0)),
          isDefault: !!get(priceGroup, 'field_is_default', false),
        };
      });

    return {
      langcode: get(tariff, 'langcode', ''),
      id: get(tariff, 'drupal_id', ''),
      name: get(tariff, 'title', ''),
      description: get(tariff, 'field_pl_description_in_cms.value', ''),
      popular: get(tariff, 'field_pl_is_popular', false),
      profits: (get(tariff, 'relationships.field_profits', []) as Paragraph__Profits[]).map(
        profit => {
          return {
            active: get(profit, 'field_profits_active', false),
            name: get(profit, 'field_profits_name.value', ''),
            reference: profit.field_ref_desc
              ? profit.field_ref_desc.map(ref => {
                  const reference = profRef.find(
                    item =>
                      item.langcode === tariff.langcode &&
                      item.drupal_internal__id === ref.target_revision_id
                  );

                  return {
                    tariffsId: get(reference, 'relationships.field_price_group_reference', []).map(
                      ref => ref.drupal_id
                    ),
                    text: get(reference, 'field_text.value', ''),
                  };
                })
              : [],
          };
        }
      ),
      recommended: get(tariff, 'relationships.field_recommended_for_branches', []).map(
        recommended => utils.escapeDiacritics(recommended.title).toLowerCase()
      ),
      prices,
    };
  });

  const allTariffsLanguages = {};

  languages.codes.map(lang => {
    allTariffsLanguages[lang] = allTariffs.filter(tariff => tariff.langcode === lang);
  });

  return allTariffsLanguages;
};

export const transformFundings = (
  fundings?: Taxonomy_Term__Pricelist_GroupConnection | null,
  removeZeros = false
): Funding[] => {
  if (!fundings) {
    return [];
  }

  const data = get(fundings, 'edges', []).map(
    (edge: Taxonomy_Term__Pricelist_GroupEdge) => edge.node
  ) as Taxonomy_Term__Pricelist_Group[];

  const allFundings = data
    .map(funding => {
      const fileImage = get(funding, 'relationships.field_pg_image') as FileImageSharp;
      // not in api, only separate fields in query (check ConfiguratorQuery.ts)
      const fileImageLarge = get(funding, 'relationships.field_pg_image_large') as FileImageSharp;

      return {
        langcode: get(funding, 'langcode', ''),
        id: get(funding, 'drupal_id', ''),
        name: get(funding, 'field_name_in_cms', ''),
        apiName: get(funding, 'field_name_in_api', ''),
        description: get(funding, 'description.value', ''),
        image: fileImage ? fileImage.localFile : null,
        imageLarge: fileImageLarge ? fileImageLarge.localFile : null,
        isPerProduct: get(funding, 'field_isperproduct', false),
        maxFundedProducts: get(funding, 'field_maxfundedproducts', 100),
        disabled: !funding.field_publish || false,
        disabledText: funding.field_text_when_unpublished
          ? funding.field_text_when_unpublished.value || ''
          : undefined,
        sidePanelInfo: get(funding, 'field_side_panel_info', ''),
        isDiscount: get(funding, 'field_is_discount', false),
        isDefault: !!get(funding, 'field_is_default', false),
      };
    })
    .filter(() => !removeZeros);

  const allFundingsLanguages = {};

  languages.codes.map(lang => {
    allFundingsLanguages[lang] = allFundings.filter(funding => funding.langcode === lang);
  });

  return allFundingsLanguages;
};

export const transformSettings = settings => {
  const configuratorSettingsLanguages = {};

  settings.edges.forEach(edge => {
    const data = edge.node;
    const { relationships, ...restData } = data || {};

    const relationshipsData = Object.keys(relationships || {}).reduce((acc, key) => {
      if (key === 'field_images' && relationships[key]) {
        return {
          ...acc,
          [key]: relationships[key].map(image => image.localFile),
        };
      }
      return {
        ...acc,
        [key]: relationships[key],
      };
    }, {});

    configuratorSettingsLanguages[data.langcode] = {
      ...relationshipsData,
      ...restData,
    };
  });

  return configuratorSettingsLanguages;
};

export const transformCalendarSettings = settings => {
  return get(settings, 'edges[0].node.field_days_to_visit', 5);
};

export const transformAgreements = (agreements): Agreement[] => {
  if (!agreements) {
    return [];
  }

  const dataAgreements = get(agreements, 'edges', []).map(edge => edge.node);

  const allAgreements = dataAgreements.sort(compare).map(agreement => {
    return {
      langcode: get(agreement, 'langcode'),
      id: get(agreement, 'drupal_id'),
      content: get(agreement, 'agreement_content.value'),
      explication: get(agreement, 'agreement_explication.value'),
      type: get(agreement, 'agreement_type'),
      pdf: {
        title: get(agreement, 'agreement_pdf_title'),
        url: get(agreement, 'relationships.agreement_pdf.localFile.url'),
      },
      paymentTypes: get(agreement, 'relationships.agreement_payment_types', []).map(paymentType =>
        get(paymentType, 'machine_api_name')
      ),
    };
  });

  return languages.codes.reduce((acc, lang) => {
    return {
      ...acc,
      [lang]: allAgreements.filter(device => device.langcode === lang),
    };
  }, {});
};

export const transformFiscalizations = (
  fiscalizations,
  priceList,
  priceListGroup
): Fiscalization[] => {
  if (!fiscalizations) {
    return [];
  }

  const dataFiscalizations = get(fiscalizations, 'edges', []).map(edge => edge.node);

  const dataPriceList = get(priceList, 'edges', []).map(edge => edge.node);

  const dataPriceListGroup = get(priceListGroup, 'edges', []).map(edge => edge.node);

  const allFiscalizations = dataFiscalizations.sort(compare).map(fiscalization => {
    const fileImage = get(fiscalization, 'relationships.image') as FileImageSharp;
    const fiscalizationIsFree = get(fiscalization, 'is_free', false);
    const fiscalizationPriceListName = 'FISC';
    const fiscalizationPriceListOptionName = get(fiscalization, 'price_list_name', null);
    const fiscalizationLangcode = get(fiscalization, 'langcode');

    const prices = dataPriceList
      .filter(
        price =>
          !fiscalizationIsFree &&
          fiscalizationLangcode &&
          get(price, 'field_pl_group_name_in_api', null) !== null &&
          fiscalizationPriceListName === get(price, 'field_pl_name') &&
          fiscalizationPriceListOptionName === get(price, 'field_pl_optionname', null) &&
          fiscalizationLangcode === get(price, 'langcode')
      )
      .map(price => {
        const priceGroup = dataPriceListGroup.find(priceListGroup =>
          findPriceListGroup(price, priceListGroup)
        );
        return {
          id: get(price, 'drupal_id', ''),
          priceListGroup: get(price, 'field_pl_group_name_in_api', ''),
          iposFee: parseFloat(get(price, 'iposfee', 0)),
          isDefault: !!get(priceGroup, 'field_is_default', false),
        };
      });

    return {
      langcode: get(fiscalization, 'langcode', ''),
      id: get(fiscalization, 'drupal_id', ''),
      title: get(fiscalization, 'title', ''),
      description: get(fiscalization, 'description.value', ''),
      appointmentAvailable: get(fiscalization, 'appointment_available', false),
      isFree: get(fiscalization, 'is_free', false),
      isPopular: get(fiscalization, 'is_popular', false),
      image: fileImage ? fileImage.localFile : null,
      availableForDevices: get(fiscalization, 'relationships.available_for_devices', []).map(
        device => get(device, 'drupal_id', '')
      ),
      disabledFiscalizationMesssage: get(fiscalization, 'disabled_fiscalization_messsage', ''),
      prices,
      calendar_text: get(fiscalization, 'calendar_text.value', ''),
    };
  });

  return languages.codes.reduce((acc, lang) => {
    return {
      ...acc,
      [lang]: allFiscalizations.filter(fiscalization => fiscalization.langcode === lang),
    };
  }, {});
};
