import React from 'react';
import Tooltip from '@components/common/Tooltip';
import TooltipIcon from '@components/common/TooltipIcon';
import styled from "@emotion/styled";
import { breakpoints, css, respondFrom } from "@styles";

const ProductTitle = styled.div`
  text-align: center;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondFrom(
  breakpoints.lg,
  css`
      padding: 12px 0;
    `
)}

  h2 {
    font-size: 20px;
    margin: 0;

    ${respondFrom(
  breakpoints.lg,
  css`
        font-size: 24px;
      `
)}
  }
`;

interface ProductCategoryTitleWithTooltipProps {
  tid: number;
  langcode: string;
  name: string;
  tooltip?: string;
}

const ProductCategoryTitle: React.FC<ProductCategoryTitleWithTooltipProps> = ({ tid, langcode, name, tooltip }) => (
  <>
    <ProductTitle key={`title-${tid}-${langcode}`}>
      <h2>{name}:</h2>
      {tooltip && (
        <TooltipIcon
          key={`tooltip-icon-${tid}-${langcode}`}
          data-tip="React-tooltip"
          data-for={`productCategory-${tid}`}
        />
      )}
    </ProductTitle>

    <Tooltip
      key={`tooltip-${tid}-${langcode}`}
      text={tooltip}
      width="medium"
      theme="light"
      id={`productCategory-${tid}`}
      effect="solid"
      border
    />
  </>
);

export default ProductCategoryTitle;