import { Product, ProductCategories } from '@store/content/types';

export const groupProducts = (products: Product[], productCategories: ProductCategories[], langcode: string) => {
  const groupedProducts = productCategories[langcode]
    .reduce((acc, category) => {
      const { tid } = category;
      if (!acc[tid]) {
        acc[tid] = [];
      }
      acc[tid][langcode] = products[langcode].filter(product => product.productCategoryId === tid);
      return acc;
    }, {});

  const defaultProducts = products[langcode].filter(product => !product.productCategoryId);

  if (defaultProducts.length) {
    if (!groupedProducts.default) {
      groupedProducts.default = {};
    }

    groupedProducts.default[langcode] = defaultProducts;
  }

  return groupedProducts;
};