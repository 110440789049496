import React from 'react';

import {Product, CennikStateDependent, ProductCategories} from '@store/types';

import Container from '@components/layout/Container';
import CennikSectionHeader from '@components/cennik/CennikSectionHeader';
import ProductsList from '@components/configurator/products/ProductsList';
import { LocationDependent } from '@content/types/general';
import { CennikSettings } from '@content/types/cennik';
import * as utils from '@utils';
import styled from '@emotion/styled';
import {groupProducts} from "../../utils/products";
import ProductCategoryTitle from "@components/common/ProductCategoryTitle";

const StyledCennikProducts = styled.section`
  margin-bottom: 20px;
`;

export interface CennikProductsProps extends CennikStateDependent, LocationDependent {
  products: Product[];
  badge?: string;
  settings: CennikSettings;
  langcode: string;
  children: React.ReactElement;
  productCategories: ProductCategories[];
}

export default (props: CennikProductsProps) => {
  const { products, productCategories, langcode } = props;
  const groupedProducts = groupProducts(products, productCategories, langcode);

  const productsListProps = {
    location: props.location,
    configurator: props.cennik,
    configuratorActions: props.cennikActions,
    basketMode: "single",
    iconPlus: true,
    badge: props.badge,
    pageSettings: props.settings.configuratorFields,
    langcode: props.langcode,
    sliderSettings: {
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.5,
          },
        },
      ],
    },
  };

  return (
    <>
      <StyledCennikProducts id="section-products">
        <Container>
          <CennikSectionHeader title={utils.SafeHtml(props.settings.sections.title1)} />
          {props.children}
          {productCategories[langcode].map((category) => {
            const { tid, name, tooltip } = category;
            return (
              <React.Fragment key={`fragment-${tid}-${langcode}`}>
                <ProductCategoryTitle
                  tid={tid}
                  langcode={langcode}
                  name={name}
                  tooltip={tooltip}
                />

                <ProductsList
                  key={`products-${tid}-${langcode}`}
                  products={groupedProducts[tid]}
                  {...productsListProps}
                />
              </React.Fragment>
            )
          })}
          {groupedProducts.default && (
            <ProductsList
              products={groupedProducts.default}
              {...productsListProps}
            />
          )}
        </Container>
      </StyledCennikProducts>
    </>
  )
}
