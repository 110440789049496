import React from 'react';
import styled from '@emotion/styled';
import * as utils from '@utils';
import Page from '@components/layout/Page';
import Tooltip from '@components/common/Tooltip';
import CennikHero from '@components/cennik/CennikHero';
import CennikProducts from '@components/cennik/CennikProducts';
import CennikFundings from '@components/cennik/CennikFundings';
import CennikTariffs from '@components/cennik/CennikTariffs';
import CennikSummaryPanel from '@components/cennik/CennikSummaryPanel';
import { ConfiguratorDataProps } from '@graphql/queries/ConfiguratorQuery';
import { RootActions, RootState } from '@store/types';
import { LocationDependent } from '@content/types/general';
import { CennikSettings } from '@content/types/cennik';
import CennikPaymentType from './CennikPaymentType';
import { SettingsDataProps } from '@graphql/queries/SettingsQuery';
import CennikHelp from './CennikHelp';
import CennikFiscalizations from '@components/cennik/CennikFiscalizations';

const StyledCennikPage = styled(Page)``;
export interface CennikProps
  extends ConfiguratorDataProps,
    RootActions,
    RootState,
    LocationDependent {
  settings: CennikSettings;
  langcode: string;
  urls: Array<string>;
  administration: SettingsDataProps;
}

class CennikComponent extends React.Component<CennikProps> {
  componentDidMount() {
    utils.toggleStaticMenu(true);
    // Set default payment type
    if (
      !!this.props.administration.settings.default_payment_type &&
      this.props.cennik.paymentType === undefined
    ) {
      this.props.cennikActions.setPaymentType(
        this.props.administration.settings.default_payment_type
      );
    }
  }

  componentWillUnmount() {
    utils.toggleStaticMenu(false);
    // clear cennik store on leave
    this.props.cennikActions.clearBasket(true);
  }

  getTooltipText = () => {
    if(this.props.cennik.basket.products.length === 0) {
      return utils.SafeHtml(this.props.settings.sections.tooltip1);
    } else if(this.props.cennik.basket.tariff === null) {
      return utils.SafeHtml(this.props.settings.sections.tooltip2);
    } else if(this.props.cennik.basket.fiscalization === null) {
      return utils.SafeHtml(this.props.settings.sections.tooltip3);
    }
  }

  render() {
    const {
      cennik,
      products,
      fundings,
      tariffs,
      cennikActions,
      location,
      settings,
      langcode,
      urls,
      fiscalizations,
      productCategories,
    } = this.props;

    return (
      <StyledCennikPage>
        <CennikHero settings={settings} />
        <CennikPaymentType
          paymentType={cennik.paymentType}
          cennikActions={cennikActions}
          settings={settings}
        />
        <CennikProducts
          products={products}
          cennik={cennik}
          cennikActions={cennikActions}
          location={location}
          badge={this.props.settings.sections.badge}
          settings={settings}
          langcode={langcode}
          productCategories={productCategories}
        >
          <CennikFundings
            fundings={utils.filterFundingsWithNoZeroDiscount(fundings[langcode])}
            cennikActions={cennikActions}
            currentFundings={cennik.basket.fundings}
            basket={cennik.basket}
            settings={settings}
            langcode={langcode}
            urls={urls}
          />
        </CennikProducts>
        <CennikTariffs
          tariffs={tariffs[langcode]}
          currentFundings={cennik.basket.fundings}
          cennikActions={cennikActions}
          currentTariff={cennik.basket.tariff}
          basket={cennik.basket}
          {...this.props}
        />
        <CennikFiscalizations
          fiscalizations={fiscalizations[langcode]}
          cennikActions={cennikActions}
          basket={cennik.basket}
          settings={settings}
        />
        <CennikSummaryPanel {...this.props}></CennikSummaryPanel>
        <CennikHelp settings={settings} />
        <Tooltip
          text={this.getTooltipText()}
          theme="light"
          id="buyDisabled"
          className={
            cennik.basket.products.length > 0 && cennik.basket.tariff !== null && cennik.basket.fiscalization !== null ? 'is-hidden' : ''
          }
          alignLeft={true}
        />

        {
          products &&
            products[langcode]
              .filter(product => {
                return product.isVirtual && !!product.tooltipTextForBranches
              })
              .map(product => {
                const productSlug = [
                  product.name.toLowerCase().split(" "),
                  product.langcode
                ].join("-");

              return (
                <Tooltip
                  key={productSlug}
                  text={product.tooltipTextForBranches}
                  width="medium"
                  theme="light"
                  id={`tooltipBranches-${productSlug}`}
                  effect="solid"
                  border
                />
              );
            })
          }
      </StyledCennikPage>
    );
  }
}

export default CennikComponent;
